import {
  createStore, createEvent,
} from 'effector'

// Определения

export const headerChanged = createEvent('headerChanged')

const $currentHeader = createStore('')

// Логика и связи

$currentHeader
  .on(headerChanged, (state, id) => id)

export default $currentHeader