import React from "react"
import {useStore} from "effector-react"
import {Chip} from "@sivasifr/ui-core/Chip"
import {Sticky, StickyContainer} from "react-sticky"
import {Link} from "gatsby"
import ReactPlayer from 'react-player'
import clsx from 'clsx'
import {Typography} from "@sivasifr/ui-core/Typography"
import styles from "./Article.module.css"
import $currentHeader, {headerChanged} from "./$article"
import $currentWidth from '../components/Layout/model'

const useIntersecting = (refs, threshold=0, rootMargin="0px") => {
  const [intersecting, setIntersecting] = React.useState(false)

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting ? entry.target : null)
      },
      {
        rootMargin: rootMargin,
        threshold: threshold
      })

    if(refs.current.length > 0) {

      refs.current.forEach((ref) => {
        observer.observe(ref)
      })
    }

    // Clean up callback
    return () => refs.current.forEach((ref) => observer.unobserve(ref))
  }, [refs, threshold, rootMargin])
  return intersecting
}

const Article = ({post, previous, next}) => {
  const refs = React.useRef([])
  const [hash, setHash] = React.useState(typeof document !== 'undefined' ? document?.location?.hash : null)
  const currentWidth = useStore($currentWidth)
  const isMobile = currentWidth < 992

  React.useEffect(()=> {
    refs.current = document.querySelectorAll('[id^="header-"]')
  }, [post])

  const visible = useIntersecting(refs, 1, "100px")

  React.useEffect(()=> {
    console.log('hash', hash)
    if(document.location.hash) {
      setTimeout(()=> {
        const node = document.querySelector(decodeURIComponent(document.location.hash))
        const y = node.getBoundingClientRect().top + window.pageYOffset - 44
        window.scrollTo({top: y, behavior: 'smooth'})
      }, 300)
    }
  }, [hash])

  React.useEffect(()=> {
    if (visible?.id) {
      headerChanged(visible.id)
    }
  }, [visible])

  const currentHeader = useStore($currentHeader)

  return (
    <StickyContainer>
      <div
        className={clsx(
          styles.blogPost,
          'mx-auto'
        )}
        style={{maxWidth: 1000}}
      >
        <article
          className={styles.main}
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <Typography
              component="h1"
              itemProp="headline"
              className="mt-3"
            >
              {post.title}
            </Typography>
            <p>{post.date}</p>
            {
              post.videoSrc && (
                <div className={clsx(
                  styles.player,
                  'mt-3'
                )}>
                  <ReactPlayer
                    url={post.videoSrc}
                    width="100%"
                    height="100%"
                    controls
                  />
                </div>
                )
            }
            {
              post.lead && (
                <Typography
                  size="xxm"
                  className="mt-3"
                  dangerouslySetInnerHTML={{ __html: post.lead }}
                />
              )
            }
          </header>
          {
            post.fullText
              ? (
                <section
                  dangerouslySetInnerHTML={{ __html: post.fullText }}
                  itemProp="articleBody"
                  className={clsx(
                    styles.body,
                    "mt-3 markdown"
                  )}
                  style={{whiteSpace: 'pre-wrap'}}
                />
              )
              : (
                <span>Текста еще нет</span>
              )
          }
          <hr />
          {
            post.tags.length > 0 && (
              <section className="px-3 pb-3">
                {
                  post.tags.map(({id, label}) => (
                    <Chip
                      key={id}
                      className="mr-1"
                      label={label}
                      color="primary"
                      component="a"
                      href={`/tags/${id}`}
                      clickable
                    />
                  ))
                }
              </section>
            )
          }
        </article>
        {
          post.tableOfContents?.length > 0 && (
            <nav className={clsx(styles.navToc, 'mt-3')}>
              <Sticky relative={isMobile}>
                {({ style, isSticky }) => (
                  <ul
                    className={styles.toc}
                    role="navigation"
                    aria-label="Table of contents"
                    {...(!isMobile && {style: {...style, top: isSticky ? '60px' : '0px'}})}
                  >
                    {
                      post.tableOfContents.map((item) => (
                        <li
                          key={item.anchorId}
                          onClick={(e) => {
                            setHash(`#header-${item.anchorId}`)
                          }}
                        >
                          <a
                            href={`#header-${item.anchorId}`}
                            className={currentHeader === `header-${item.anchorId}` ? styles.active : ''}
                          >{item.label}</a>
                        </li>
                      ))
                    }
                  </ul>
                )}
              </Sticky>
            </nav>
          )
        }
      </div>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.id} rel="prev">
                ← {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.id} rel="next">
                {next.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </StickyContainer>
  )
}

export default Article