import React from "react"
import { ReactReader } from "react-reader"

const storage = global.localStorage || null

const Epub = ({ post }) => {

  const [location, setLocation] = React.useState(storage && storage.getItem("epub-location") ? storage.getItem("epub-location") : 2)

  const handleLocationChanged = (location) => {
    setLocation(location)
    storage && storage.setItem("epub-location", location)
  }

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <ReactReader
        url={post.epubSrc}
        title={post.title}
        location={location}
        locationChanged={handleLocationChanged}
      />
    </div>
  )
}

export default Epub