import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Article from './Article'
import Epub from './Epub'

const BlogPostTemplate = ({ data, location }) => {

  const post = data.post
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.title}
        description={post.logLine}
      />
      {post.kind === 'ARTICLE' && (
        <Article
          post={post}
          previous={previous}
          next={next}
        />
      )}
      {post.kind === 'EPUB' && (
        <Epub
          post={post}
        />
      )}
    </Layout>
  )
}

export default BlogPostTemplate

export const blogPostTemplateQuery = graphql`
  query BlogPostTemplate(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    post(id: { eq: $id }) {
      id
      title
      fullText
      logLine
      lead
      epubSrc
      tableOfContents {
        label
        anchorId
      }
      tags {
       id
       label
      }
      kind
      videoSrc
    }
    previous: post(id: { eq: $previousPostId }) {
      id
      title
    }
    next: post(id: { eq: $nextPostId }) {
      id
      title
    }
  }
`
